.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px !important;
}

.swiper {
    padding: 10px 0px !important;
}


.swiper-pagination-bullet {
    background-color: #f9562456; /* Change to your desired color */
}

/* Change the active bullet color */
.swiper-pagination-bullet-active {
    background-color: #F95424; /* Change to your desired color */
}
.swiper-button-prev {
    width: 20px;
    height: 20px;
    left: 0px !important;
}

.swiper-button-next {
    color: #F95424 !important;
    background-color: #f954242b;
    border-radius: 50%;
    border: 1px solid #f954244d;
    width: 57px !important;
    height: 57px !important;
}

.swiper-button-prev {
    color: #F95424 !important;
    background-color: #f954242b;
    border-radius: 50%;
    border: 1px solid #f954244d;
    width: 57px !important;
    height: 57px !important;

}

@media (max-width: 600px) {

    /* svg {
        width: 15px;
        height: 15px;
    } */
    .swiper-button-next {
        width: 20px !important;
        height: 20px !important;
    }

    .swiper-button-prev {
        width: 20px !important;
        height: 20px !important;
    }

    .swiper-button-next:after {
        font-size: 13px !important;
        left: -10px !important;

    }

    .swiper-button-prev:after {
        font-size: 13px !important;
        left: 0px !important;
    }
}