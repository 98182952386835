@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom thin scrollbar */
.scrollbar-thin::-webkit-scrollbar {
  /* width: 4px; */
  /* Adjust width as needed */
  height: 5px;
  /* For horizontal scrollbar */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgb(168, 168, 168);
  /* Customize thumb color */
  border-radius: 10px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}