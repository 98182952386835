.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-bounce {
  animation: bounce 4s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
    animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateY(5%);
    animationTimingFunction: cubic-bezier(0, 0, 0.9, 1);
  }
}

.icon-path {
  fill: #f97316;
  /* Initial fill color */
  transition: fill 0.3s;
  /* Smooth transition for hover effect */
}

.icon-path:hover {
  fill: white;
  /* Fill color on hover */
}


  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
